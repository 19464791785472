<template>
  <div class="animated fadeIn container">
    <h1>충전관리</h1>
    <b-input-group class="mt-4" style="max-width:300px" v-show="$store.state.isAdmin">
      <b-form-input
        type="text"
        class="form-control"
        placeholder="회원명 또는 이메일"
        v-model="searchKeyword"
        @keydown.native="searchKeywordInputKeyDown"
      />
      <b-btn variant="primary" class="fl" @click.prevent="changeSearchKeyword">검색</b-btn>
    </b-input-group>
    <table class="border-table hover-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td width="20%">결제시각</td>
          <td width="50%">입금자명</td>
          <td width="20%">금액</td>
          <td width="20%">상태</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items" @click="pushDetail(item)">
          <td>
            <span class="mr-3">{{ item.createdAt | dateFormat }}</span>
          </td>
          <td>
            {{ item.user.username }}
          </td>
          <td>{{ item.amount | numberFormat }} 원</td>
          <td>
            {{ statusText(item.status) }}
          </td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      align="center"
      :total-rows="totalCount"
      v-model="currentPage"
      :per-page="20"
      :limit="10"
      class="mt-4 "
      @change="changePage"
    ></b-pagination>
  </div>
</template>

<script>
import PaymentService from '@/services/PaymentService'

export default {
  name: 'PaymentHistory',
  data() {
    return {
      items: [],
      totalCount: 1,
      currentPage: 1,
      searchKeyword: null,
      userId: null,
    }
  },
  created() {
    this.searchKeyword = this.$route.query.searchKeyword
    this.currentPage = parseInt(this.$route.query.page) || 1
    this.userId = this.$route.query.userId
    this.getList()
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  methods: {
    async getList() {
      var params = {
        page: this.currentPage,
        limit: this.limit,
      }

      if (this.searchKeyword) {
        params.searchKeyword = this.searchKeyword
      }

      if (this.userId) {
        params.userId = this.userId
      }

      const response = await PaymentService.list(params)

      if (response.status == 200) {
        this.items = response.data.list
        this.totalCount = response.data.totalCount
        this.currentPage = parseInt(this.$route.query.page) || 1
      }
    },
    pushDetail(item) {
      if (item.payMethod == 'vbank') {
        this.$router.push('/payment/vbank/' + item.id)
      }
    },
    statusText(status) {
      var item = {
        ready: '미결제',
        paid: '결제완료',
        cancelled: '결제취소',
        failed: '결제실패',
      }
      return item[status]
    },
    changePage(page) {
      var query = {
        page: page,
      }

      if (this.searchKeyword && this.searchKeyword.length > 0) {
        query.searchKeyword = this.searchKeyword
      }

      if (this.userId) {
        query.userId = this.userId
      }

      this.$router.push({
        path: '/payment/history',
        query: query,
      })
    },
    changeSearchKeyword() {
      this.$router.push({
        path: '/payment/history',
        query: {
          searchKeyword: this.searchKeyword,
        },
      })
    },
    searchKeywordInputKeyDown(e) {
      if (e.which === 13) {
        this.changeSearchKeyword()
      }
    },
  },
  watch: {
    '$route.query.searchKeyword'() {
      this.searchKeyword = this.$route.query.searchKeyword
      this.getList()
    },
    '$route.query.page'() {
      this.currentPage = this.$route.query.page
      this.getList()
    },
    '$route.query.userId'() {
      this.userId = this.$route.query.userId
      this.getList()
    },
  },
}
</script>
